import React, { FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';

import { StripeProps } from './types';
import { options, stripePromise } from './constants';

const StripeProvider: FC<StripeProps> = ({ children }) => (
  <Elements stripe={stripePromise} options={options}>
    {children}
  </Elements>
);

export default StripeProvider;
