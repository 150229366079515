import { useState, useEffect, useRef, ReactNode } from 'react';
import { IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { TypeAnimation } from 'react-type-animation';

import Container from '../../components/shared/Container';
import { depthPrecision, visionaryPrecision } from '../../assets/images/index';
import PrimaryButton from '../buttons/PrimaryButton';

interface HeaderSection {
  title: ReactNode;
  subtitle: string;
  image: string;
}

const headerInfo: HeaderSection[] = [
  {
    title: (
      <h1 className='xl:text-[50px] font-Avenir-Next-Pro lg:text-[50px] text-[32px] font-semibold leading-[40px] md:leading-[50px] lg:leading-[65px] w-[100%]  tracking-wide transition-opacity ease-in duration-700 opacity-100'>
        Precision <br /> beyond{' '}
        <span className='text-accent'>
          <TypeAnimation sequence={['sight', 2000, 10000]} speed={10} repeat={Infinity} />
        </span>
      </h1>
    ),
    subtitle:
      ' Experience the future of vision enhancement, tailored to perfection for clarity and accuracy beyond the visible spectrum.',
    image: '/images/eye.png',
  },
  {
    title: (
      <h1 className='xl:text-[50px] lg:text-[45px] font-Avenir-Next-Pro text-[32px] font-semibold leading-[40px] md:leading-[50px] lg:leading-[65px] w-[100%]  tracking-wide'>
        Explore the <br /> depths of{' '}
        <span className='text-accent'>
          <TypeAnimation
            sequence={['precision', 2000, 10000]}
            speed={10}
            repeat={Infinity}
          />
        </span>
      </h1>
    ),
    subtitle:
      'Our visionary technology pushes the boundaries of sight, offering unparalleled clarity and accuracy in every view',
    image: '/images/people.png',
  },
  {
    title: (
      <h1 className='xl:text-[50px] lg:text-[45px] text-[32px]  font-Avenir-Next-Pro font-semibold leading-[40px] md:leading-[50px] lg:leading-[65px] w-[100%]  tracking-wide'>
        Where AI meets <br />{' '}
        <span className='text-accent'>
          <TypeAnimation sequence={['visionary', 2000, 10000]} speed={10} repeat={Infinity} />
        </span>{' '}
        precision
      </h1>
    ),
    subtitle:
      'Delve into deeper insights and enhanced clarity, guided by our sophisticated metrics and AI-driven technology',
    image: '/images/vehicle.png',
  },
];
const analyticsInfo = [
  'Predictive Visual Insights',
  'Crowd Flow Analytics',
  'Vehicle Detection & Monitoring',
  'Traffic Monitoring & Control',
];

const HeroSection = ({ onClickScheduleCall }: { onClickScheduleCall?: () => void }) => {
  const [index, setIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [animating, setAnimating] = useState(false);
  const [analyticIndex, setAnalyticIndex] = useState(0);

  const changeSlide = (newIndex: number) => {
    setAnimating(true);
    setTimeout(() => {
      setIndex(newIndex);
      setAnimating(false);
    }, 500);
  };
  const onNext = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    const newIndex = (index + 1) % headerInfo.length;
    changeSlide(newIndex);
    setAnalyticIndex(analyticIndex + 1);
  };

  const onPrev = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    const newIndex = (index - 1 + headerInfo.length) % headerInfo.length;
    changeSlide(newIndex);
    setAnalyticIndex(analyticIndex - 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      onNext();
    }, 12000);
    return () => clearInterval(interval);
  }, [index]);

  return (
    <div className='relative bg-blend-darken '>
      <Container className='flex flex-col md:flex-row gap-x-10 gap-y-10 xl:pt-[150px] lg:py-24 py-14 z-10'>
        <div
          className={` md:w-[45%] xl:w-[40%] md:ml-[5%] w-full mt-12 xl:mt-0 ${
            animating ? 'hero-image-exit' : 'hero-image-enter'
          }`}
        >
          <div className='w-[100%] flex flex-col gap-y-4'>
            {headerInfo[index].title}
            <span className='md:w-[80%] xl:w-[60%] md:h-[200px] lg:h-[180px] leading-[40px]'>
              {headerInfo[index].subtitle}
            </span>
          </div>
          <div className='gap-5 mt-8 md:mt-8 flex md:justify-start justify-center'>
            <PrimaryButton
              className='bg-accent dark:bg-white dark:text-accent md:text-[16px] text-[14px] md:!min-w-[300px]'
              isHover
              isArrow
              onClick={onClickScheduleCall}
            >
              Schedule a Call
            </PrimaryButton>
          </div>
        </div>
        <div className='md:w-[50%] xl:w-[55%] w-full mt-12 xl:mt-0 relative flex flex-col'>
          <div
            className={` ${
              animating ? 'hero-image-exit' : 'hero-image-enter'
            }   center-child hero-image rounded-[30px]  opacity-30 h-[450px] lg:h-[500px]`}
            style={{
              backgroundImage: `url(${headerInfo[index].image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
            }}
          ></div>
          <div className='text-xs absolute bg-accent/80 backdrop-blur-md h-fit w-[70%] md:w-full lg:w-[400px] xl:w-[500px] bottom-[-2.5rem] lg:bottom-[-3.5rem] md:bottom-[-3rem] lg:right-8 xl:right-16 right-[15%] md:right-0 rounded-[20px] flex flex-col justify-center items-center py-4 md:py-3 px-4 md:px-4 lg:px-8 shadow-lg z-10'>
            <h1 className='text-center text-base md:text-lg lg:text-xl font-semibold text-white font-Avenir-Next-Pro tracking-wide'>
              {analyticsInfo[index]}
            </h1>
            <div>
              <div className='flex items-center justify-center mt-4'>
                {/* Previous Button */}
                <button
                  className='rounded-full p-3 bg-gradient-to-br from-blue-500 to-blue-700 text-white mr-6 shadow-md hover:scale-110 transition-transform'
                  onClick={onPrev}
                  aria-label='Previous Slide'
                  type='button'
                >
                  <IoMdArrowBack className='text-xl' />
                </button>

                {/* Indicator Dots */}
                <div className='flex items-center gap-3'>
                  {headerInfo.map((slides, i) => (
                    <div
                      key={slides.image}
                      className={`rounded-full transition-all duration-300 h-3 w-3 ${
                        index === i
                          ? 'bg-gradient-to-br from-white to-blue-400 scale-125'
                          : 'bg-white/40'
                      }`}
                    />
                  ))}
                </div>

                {/* Next Button */}
                <button
                  className='rounded-full p-3 bg-gradient-to-br from-white to-blue-300 text-blue-700 ml-6 shadow-md hover:scale-110 transition-transform'
                  onClick={onNext}
                  aria-label='Next Slide'
                  type='button'
                >
                  <IoMdArrowForward className='text-xl' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HeroSection;
