import Container from 'components/shared/Container';
import IndustriesHero1 from 'assets/images/industriesPage/hero1.png';
import IndustriesHero2 from 'assets/images/industriesPage/hero2.png';
import IndustriesHero3 from 'assets/images/industriesPage/hero3.png';
import IndustriesHero4 from 'assets/images/industriesPage/hero4.png';

const imageSet1 = [IndustriesHero1, IndustriesHero2];
const imageSet2 = [IndustriesHero3, IndustriesHero4];

const HeroSection = () => {
  return (
    <>
      <section className=' flex pt-[5rem]  pb-7 md:pb-0 '>
        <Container>
          <figure className='grid grid-cols-1 gap-x-10   md:grid-cols-2 relative z-30'>
            <figcaption className='flex flex-col justify-center py-10 lg:py-0 mb-10'>
              <h1 className='xl:text-[50px] lg:text-[50px] text-[32px] xl:leading-[80px] lg:leading-[60px] leading-[45px] tracking-wide max-w-[32.125rem] font-semibold font-Avenir-Next-Pro'>
                Industries We Serve
              </h1>
              <p>
                Experience the future of vision enhancement, tailored to perfection for clarity and
                accuracy beyond the visible spectrum.
              </p>
            </figcaption>

            <ul className='flex gap-x-7 md:gap-x-9 lg:gap-x-11 mt-8'>
              <li>
                <ol className='flex flex-col gap-y-7 md:gap-y-20 lg:gap-y-11 py-4'>
                  {imageSet1.map((src, index) => (
                    <li key={index}>
                      <img src={src} className='w-full max-w-[18.657rem]' alt='Industries' />
                    </li>
                  ))}
                </ol>
              </li>

              <li>
                <ol className='flex flex-col gap-y-7 md:gap-y-20 lg:gap-y-11 py-4'>
                  {imageSet2.map((src, index) => (
                    <li key={index}>
                      <img src={src} className='w-full max-w-[18.657rem]' alt='Industries' />
                    </li>
                  ))}
                </ol>
              </li>
            </ul>
          </figure>
        </Container>
      </section>
    </>
  );
};

export default HeroSection;