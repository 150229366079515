import React from 'react';
import Container from 'components/shared/Container';

import { 
  PrivacyNotice,
  SummaryOfKeyPoints,
  WhatInformationWeCollect,
  HowWeProcessYourInformation,
  LegalBasesForProcessingInformation,
  SharingPersonalInformation,
  TrackingTechnologies,
  ArtificialIntelligenceProducts,
  HowLongDoWeKeepYourInformation,
  HowDoWeKeepYourInformationSafe,
  DoWeCollectInformationFromMinors,
  WhatAreYourPrivacyRights,
  ControlsForDoNotTrack,
  UnitedStatesResidentsSpecificPrivacyRights,
  DoWeMakeUpdatesToThisNotice,
  HowCanYouContactUsAboutThisNotice,
  HowCanYouReviewUpdateOrDeleteTheDataWeCollect,
} from './Heading';


const tableOfContents = [
  { title: "1. WHAT INFORMATION DO WE COLLECT?", id: "what-information-we-collect" },
  { title: "2. HOW DO WE PROCESS YOUR INFORMATION?", id: "how-we-process-your-information" },
  { title: "3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?", id: "legal-bases-for-processing-information" },
  { title: "4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?", id: "sharing-personal-information" },
  { title: "5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?", id: "tracking-technologies" },
  { title: "6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?", id: "artificial-intelligence-products" },
  { title: "7. HOW LONG DO WE KEEP YOUR INFORMATION?", id: "how-long-do-we-keep-your-information" },
  { title: "8. HOW DO WE KEEP YOUR INFORMATION SAFE?", id: "how-do-we-keep-your-information-safe" },
  { title: "9. DO WE COLLECT INFORMATION FROM MINORS?", id: "do-we-collect-information-from-minors" },
  { title: "10. WHAT ARE YOUR PRIVACY RIGHTS?", id: "what-are-your-privacy-rights" },
  { title: "11. CONTROLS FOR DO-NOT-TRACK FEATURES", id: "controls-for-do-not-track" },
  { title: "12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?", id: "us-residents-privacy-rights" },
  { title: "13. DO WE MAKE UPDATES TO THIS NOTICE?", id: "updates-to-notice" },
  { title: "14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?", id: "contact-us" },
  { title: "15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?", id: "review-update-delete-data" },
];


const PrivacyContent : React.FC = () => {

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      console.error(`Section with ID ${id} not found.`);
    }
  };

  return (
    <div className="mt-10">
      <Container className="my-20">
        <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 mt-10">
            <h1 className="text-[30px] font-[900] font-Avenir-Next-Pro " style={{ color: "#0054AA" }}>
            HawkeyeVisionTek Privacy Policy
            </h1>
            <p className="text-base md:text-[18px] font-[900] ">
              <strong>Last updated:</strong> January 12, 2025
            </p>
          </div>
          {/* Before TOC*/}
        < PrivacyNotice/>
        <SummaryOfKeyPoints/>
          <div className="py-4" id="table-of-contents">
            <h2 className="text-2xl font-semibold">TABLE OF CONTENTS</h2>
            <ul className="list-none pl-0">
              {tableOfContents.map((tocItem, index) => (
                <li key={index} className="py-1">
                  <button
                    className="text-lg text-blue-600 hover:underline"
                    onClick={() => scrollToSection(tocItem.id)}
                  >
                    {tocItem.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <WhatInformationWeCollect/>
          <HowWeProcessYourInformation/>
          <LegalBasesForProcessingInformation/>
          <SharingPersonalInformation/>
          <TrackingTechnologies/>
          <ArtificialIntelligenceProducts/>
          <HowLongDoWeKeepYourInformation/>
          <HowDoWeKeepYourInformationSafe/>
          <DoWeCollectInformationFromMinors/>
          <WhatAreYourPrivacyRights/>
          <ControlsForDoNotTrack/>
          <UnitedStatesResidentsSpecificPrivacyRights/>
          <DoWeMakeUpdatesToThisNotice/>
          <HowCanYouContactUsAboutThisNotice/>
          < HowCanYouReviewUpdateOrDeleteTheDataWeCollect/>
        </div>
      </Container>
    </div>
  );
};

export default PrivacyContent;

