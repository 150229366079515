import { useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { themeSelector } from 'store/slices/themeSlice/selector';

interface DarkModeContextProps {
  darkMode: boolean;
}

export const useDarkMode = (): DarkModeContextProps => {
  const darkMode = useAppSelector(themeSelector);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
    document.body.classList.toggle('dark', darkMode);
  }, [darkMode]);

  return { darkMode };
};

export default useDarkMode;
