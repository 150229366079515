const industries = '/industries';

const paths = {
  HOME: '/',

  // Industries and sub its sub pages
  INDUSTRIES_PAGE: industries,
  MINISTRY_PAGE: industries + '/congregation',
  HOSPITALITY_PAGE: industries + '/hospitality',
  TRANSPORT_PAGE: industries + '/transport',
  CONSTRUCTION_PAGE: industries + '/construction',
  PRODUCTION_PAGE: industries + '/production',
  EVENTS_PAGE: industries + '/events',
  PRIVACY_PAGE:'/privacy',
  TERMS_PAGE: '/terms',
  CONTACT_US: '/contact-us',

  // Wildcard
  notFound: '/404',
};

export default paths;
