import React, { useState } from 'react';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { SvgIconProps } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import PrimaryButton from 'components/buttons/PrimaryButton';
import Container from 'components/shared/Container';
import { contactFormSchema } from 'constants/Schemas';
import { ControlledInput, NumberControlledInput } from 'components';
import { postContactRequest } from 'api/contactUs';
import { ContactUsToastMessages } from 'constants/ToastMessages';
import { VoidCallback } from 'types';

import { xLink, instagramLink } from '../../constants';

interface ContactType {
  value: string;
  link?: string;
  icon: React.FC<SvgIconProps>;
}

const contacts: ContactType[] = [
  {
    value: '(682) 422 7195',
    icon: PhoneInTalkIcon,
    link: 'tel:(682) 422 7195',
  },
  {
    value: 'hello@hawkeyevisiontek.com',
    icon: EmailIcon,
    link: 'mailto:hello@hawkeyevisiontek.com',
  },

  {
    value: '630 E Southlake Blvd. #133 Southlake, TX 76092',
    icon: LocationOnIcon,
  },
];

const socials = [
  {
    icon: XIcon,
    link: xLink,
  },
  {
    icon: InstagramIcon,
    link: instagramLink,
  },
];

type TContactForm = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  message: string;
};

type TContactFormProps = Partial<{
  id: string;
  containerClassName: string;
  contentContainerClassName: string;
  isModal: boolean;
  onClose: VoidCallback;
}>;

export default function ContactForm({
  id,
  containerClassName,
  contentContainerClassName,
  isModal = false,
  onClose,
}: TContactFormProps) {
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
  } = useForm<TContactForm>({
    resolver: yupResolver(contactFormSchema),
  });

  const [isSendingInquiry, setIsSendingInquiry] = useState<boolean>(false);

  const onSubmitContactForm: SubmitHandler<TContactForm> = (formData) => {
    const toastId = toast.loading(ContactUsToastMessages.POST_CONTACT_START);
    setIsSendingInquiry(true);

    postContactRequest(formData)
      .then(() => {
        toast.success(ContactUsToastMessages.POST_CONTACT_SUCCESS, { id: toastId });
        reset({ first_name: '', last_name: '', email: '', phone_number: '', message: '' }); // clear form
      })
      .catch(() => {
        toast.error(ContactUsToastMessages.POST_CONTACT_FAILURE, { id: toastId });
      })
      .finally(() => {
        setIsSendingInquiry(false);
      });
  };

  return (
    <Container id={id} className={containerClassName}>
      {isModal ? (
        <div className='w-full flex flex-row justify-end absolute p-2 md:right-[1%] md:top-[1%]'>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
      ) : null}
      <div
        className={classNames('', {
          'md:ml-10 m-2 my-8 md:m-[100px] bg-pill-bg dark:bg-industry-hero-dark dark:text-white p-2 rounded-[10px] flex flex-col-reverse lg:flex-row gap-8':
            !contentContainerClassName,
          [`${contentContainerClassName}`]: !!contentContainerClassName,
        })}
      >
        <div
          className={classNames(
            'dark:bg-[#F0F7FF] bg-accent rounded-[10px] p-4 md:p-10 text-white dark:text-accent flex flex-col justify-evenly gap-20  w-full lg:w-[750px]  relative overflow-hidden',
            {
              flex: isModal,
            },
          )}
        >
          <div className=''>
            <img
              src='images/circle.svg'
              alt=''
              className='object-fit absolute translate-x-0 bottom-5 md:bottom-10 right-10 z-20 scale-50 md:scale-75'
            />
            <img
              src='images/pie.svg'
              alt=''
              className='object-fit absolute translate-x-10 -bottom-10 right-3 scale-75 md:scale-90'
            />
          </div>
          <div>
            <h2 className='text-[30px] whitespace-nowrap  font-Avenir-Next-Pro'>
              {' '}
              Contact Information
            </h2>
            <p className='text-[18px]'>You can reach us on:</p>
          </div>
          <div className='flex flex-col gap-8 max-w-[300px]'>
            {contacts.map(({ value, icon: Icon, link }, index) => (
              <a key={index} className='flex items-center gap-5 cursor-pointer' href={link}>
                <Icon className='' />
                <p className='text-[16px]'>{value}</p>
              </a>
            ))}
          </div>
          <div className='flex gap-4 items-center cursor-pointer'>
            {socials.map(({ icon: Icon, link }, index) => (
              <a key={link} href={link} target='_blank' rel='noreferrer'>
                <Icon key={index} />
              </a>
            ))}
          </div>
        </div>
        <div className='w-full p-4 md:p-10 flex flex-col justify-start my-7 md:my-5'>
          <div className='flex flex-col gap-8 justify-center'>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-10 w-full'>
              <ControlledInput
                control={control}
                required
                disabled={isSendingInquiry}
                label='First Name'
                name='first_name'
                placeholder='First Name'
                error={!!errors?.first_name}
                helperText={(errors?.first_name?.message as string) ?? ''}
              />
              <ControlledInput
                control={control}
                required
                disabled={isSendingInquiry}
                name='last_name'
                label='Last Name'
                placeholder='Last Name'
                error={!!errors.last_name}
                helperText={errors.last_name?.message ?? ''}
              />
            </div>
            <div className='gap-10 w-full flex-col grid grid-cols-1 md:grid-cols-2 '>
              <ControlledInput
                control={control}
                required
                disabled={isSendingInquiry}
                label='Email'
                name='email'
                placeholder='Email'
                error={!!errors.email}
                helperText={errors.email?.message ?? ''}
              />
              <NumberControlledInput
                required
                disabled={isSendingInquiry}
                control={control}
                name='phone_number'
                label='Phone Number'
                error={!!errors.phone_number}
                placeholder='Phone Number'
                helperText={errors.phone_number?.message ?? ''}
              />
            </div>

            <ControlledInput
              control={control}
              disabled={isSendingInquiry}
              required
              label='Message'
              name='message'
              placeholder='Message'
              error={!!errors?.message}
              helperText={(errors?.message?.message as string) ?? ''}
              multiline={true}
              rows={10}
            />
            <div className='flex md:justify-end'>
              <PrimaryButton
                className='w-full md:w-fit bg-black'
                onClick={handleSubmit(onSubmitContactForm)}
                disabled={isSubmitting || !isDirty || isSendingInquiry}
              >
                Send Message
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
