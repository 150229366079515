import { TTabNavigatorItem } from 'components/views/TabNavigator/types';

export type RoutesProps = {
  path: string;
  isProtected: boolean;
  component: JSX.Element;
} & Partial<{
  withNavbar: boolean;
  isLanding: boolean;
  withLogoHeader: boolean;
  isProtectedWithSession: boolean;
  tabNavigatorTitle: string;
  withTabNavigator: boolean;
  tabNavigatorOptions: TTabNavigatorItem[];
}>;

export enum Routes {
  Error = '*',
  Landing = '/',
  Home = '/home',
  Team = '/team',
  Login = '/login',
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  Success = '/success',
  Profile = '/profile',
  Terms = '/terms',
  Privacy = '/privacy',
  ProcessForm = '/process-form',
  OrganizationsList = '/organizations-list',
  Industriespage = '/industries',
  Hospitality = '/industries/hospitality',
  Ministries = '/industries/congregation',
  Transport = '/industries/transport',
  Construction = '/industries/construction',
  Production = '/industries/production',
  Events = '/industries/events',
  NotFound = '/404',
  ContactUs = '/contact-us',
  Help = '/help',
  ObjectDetector = '/ObjectDetector',
}
