import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { mediaApi, teamsApi } from 'api';

import { TAllTeamsMembersParams, TAllTeamsParams, TCreateTeamParams, TUpdateTeam } from './type';
import { TeamToastMessages } from 'constants/ToastMessages';
import { TMediaParams } from 'api/media/types';

export const getTeams = createAsyncThunk('teamsSlice/getTeamsRequest', async (id: number) => {
  try {
    const response = await teamsApi.getTeamsRequest(id);
    return response.data;
  } catch (error) {
    const Error = error as AxiosError;

    throw Error;
  }
});

export const getAllTeams = createAsyncThunk(
  'teamsSlice/getAllTeamsRequest',
  async (options: TAllTeamsParams) => {
    try {
      const response = await teamsApi.getAllTeamsRequest(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getTeamMember = createAsyncThunk(
  'teamsSlice/getAllTeamMembersRequest',
  async (id: number) => {
    try {
      const response = await teamsApi.getTeamMemberRequest(id);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getAllTeamMember = createAsyncThunk(
  'teamsSlice/getAllTeamsMembersRequest',
  async (options: TAllTeamsMembersParams) => {
    try {
      const response = await teamsApi.getAllTeamMembersRequest(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error?.response?.status;
    }
  },
);

export const postNewTeam = createAsyncThunk(
  'devicesSlice/postNewTeamsRequest',
  async (params: TCreateTeamParams) => {
    try {
      const response = await teamsApi.createNewTeam(params);
      toast.success('Team successfully created!');
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message || 'Unknowned error occured');
      throw Error;
    }
  },
);

export const postNewTeamMember = createAsyncThunk(
  'devicesSlice/postNewTeamMembersRequest',
  async (body: TCreateTeamParams) => {
    try {
      const response = await teamsApi.createNewTeamMember(body);
      toast.success('Team member successfully created!');
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message || 'Unknowned error occured');
      throw Error;
    }
  },
);

export const getTeamMemberByID = createAsyncThunk(
  'teamsSlice/getTeamsMembersByIDRequest',
  async (id: number) => {
    try {
      const response = await teamsApi.getTeamMemberByIDRequest(id);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getTeamByIDRequest = createAsyncThunk(
  'teamsSlice/getTeamsByIDRequest',
  async (id: number) => {
    try {
      const response = await teamsApi.getTeamByIDRequest(id);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const deleteTeamMember = createAsyncThunk(
  'teamsSlice/deleteTeamsMembersByIDRequest',
  async (id: number, { dispatch }) => {
    try {
      const response = await teamsApi.deleteTeamMemberbyIDRequest(id);
      toast.success('Team member successfully deleted!');
      const options = {
        limit: 10,
        offset: 0,
      };
      dispatch(getAllTeams(options));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const deleteTeam = createAsyncThunk(
  'teamsSlice/deleteTeamByIDRequest',
  async (id: number, { dispatch }) => {
    try {
      const response = await teamsApi.deleteTeamRequest(id);
      // toast.warning("Team does not exist!");
      const options = {
        limit: 10,
        offset: 0,
      };
      dispatch(getAllTeams(options));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const editTeamMember = createAsyncThunk(
  'teamsSlice/editTeamsMembersByIDRequest',
  async (body: any) => {
    try {
      const response = await teamsApi.editTeamMemberRequest(body);
      toast.success('Team member updated successfully!');
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const updateTeam = createAsyncThunk(
  'teamsSlice/updateTeam',
  async (update_team: FormData, { dispatch }) => {
    try {
      const response = await teamsApi.updateTeamRequest(update_team);
      const options = {
        limit: 10,
        offset: 0,
      };
      await dispatch(getAllTeams(options));

      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(TeamToastMessages.TEAM_UPDATE_FAILURE);

      throw Error;
    }
  },
);

export const getSignedUrls = createAsyncThunk(
  'teamsSlice/getBatchUrls',
  async (params: TMediaParams) => {
    try {
      const response = await mediaApi.getSignedMediaUrl(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);
