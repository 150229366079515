import { Navigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';

import { Routes } from 'types';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { userDataSelector } from 'store/slices/authSlice/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { EInviteStatus } from 'store/slices/invitationsSlice/types';
import { acceptEmailInvitation, rejectEmailInvitation } from 'store/thunks';

const PublicRoute = ({ children }: any) => {
  const userData = useAppSelector(userDataSelector);
  const dispatch = useAppDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const token =
    urlParams.get('access_token') || BrowserStorageService.get(BrowserStorageKeys.AccessToken);
  const inviteStatus = urlParams.get('status');
  const isRejectingInvitation = inviteStatus === EInviteStatus.REJECTED;
  const isAcceptingInvitation = inviteStatus === EInviteStatus.ACCEPTED;
  const invitation_token = urlParams.get('token') as string;
  const saved_invitation_token = BrowserStorageService.get(BrowserStorageKeys.InvitationToken);
  const invitation_email = urlParams.get('email') as string;

  const isFirstTimeUser =
    BrowserStorageService.get(BrowserStorageKeys.IsFirstTimeUser, {
      session: true,
    }) === 'true';

  if (token && isFirstTimeUser && !invitation_token && !saved_invitation_token) {
    return <Navigate to={Routes.ProcessForm} />;
  }

  return children;
};

export default PublicRoute;
