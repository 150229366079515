import { lazy, Suspense, useState, useEffect } from 'react';

import BlurBlue from 'components/style/BlurBlue';

import NavBar from '../../components/shared/NavBar';
import Analytics from '../../components/home/Analytics';
import HeroSection from '../../components/home/HeroSection';
import Decision from '../../components/home/Decision';
import Experience from '../../components/home/Experience';
import DiverseIndustries from '../../components/home/DiverseIndustries';
import OtherFeatures from '../../components/home/OtherFeatures';
import WorkSmarter from '../../components/home/WorkSmarter';
import Footer from '../../components/shared/Footer';
const ContactForm = lazy(() => import('components/contact-us/ContactForm'));
const CustomModal = lazy(() => import('components/Modal'));

const Home = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  function onOpenContactForm() {
    setIsContactFormOpen(true);
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setBackgroundLoaded(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  ``;

  function onCloseContactForm() {
    setIsContactFormOpen(false);
  }

  return (
    <div className='w-full mx-auto overflow-hidden font-Roboto bg-white dark:bg-darkBg'>
      <div className='w-full'>
        <BlurBlue />
        <div
          className=' bg-white dark:bg-darkBg to-[#0054AA] dark:from-[#1f1f1f] dark:via-[#3a3a3a] dark:to-[#1f1f1f] w-full min-h-[700px] bg-cover relative'
          style={{ backgroundImage: 'url(/images/lens.png)', backgroundPosition: 'right bottom' }}
        >
          <div className='absolute min-h-[700px] top-0 bottom-0 inset-0 bg-gradient-to-r from-white to-white/40 dark:from-[#1f1f1f] dark:to-[#1f1f1f]/40'></div>
          <NavBar />
          {backgroundLoaded && (
            <>
              <HeroSection onClickScheduleCall={onOpenContactForm} />
            </>
          )}
        </div>
      </div>
      <Analytics />
      <Decision onClickScheduleCall={onOpenContactForm} />
      <Experience />
      <DiverseIndustries />
      <OtherFeatures />
      <WorkSmarter onClickScheduleCall={onOpenContactForm} />
      <Footer />
      <Suspense fallback={null}>
        <CustomModal
          isOpen={isContactFormOpen}
          onClose={onCloseContactForm}
          className='!p-0 w-[90vw] md:!w-[75vw] h-auto mx-auto mt-2'
          useClassNameOnly={true}
        >
          <ContactForm
            isModal
            containerClassName='!px-0 !w-full relative'
            contentContainerClassName='bg-pill-bg dark:bg-industry-hero-dark dark:text-white rounded-[10px] flex flex-col-reverse lg:flex-row gap-8'
            onClose={onCloseContactForm}
          />
        </CustomModal>
      </Suspense>
    </div>
  );
};

export default Home;
