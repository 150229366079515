import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import { useUserOrganizationsList } from 'hooks';
import { BrowserStorageKeys, BrowserStorageService } from 'services';
import { Routes } from 'types';

import type { TLandingLayoutProps } from './type';

const LandingLayout: FC<TLandingLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  // const { getAllOrganizations } = useUserOrganizationsList();

  const token = BrowserStorageService.get(BrowserStorageKeys.AccessToken);

  useEffect(() => {
    if (!pathname.includes(Routes.Industriespage)) window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  useEffect(() => {
    // if (token) getAllOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{children}</div>;
};

export default LandingLayout;
