import { RouterProvider } from '../routes';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import store from './store';
import { StripeProvider } from '../context';
import React from 'react';

const HawkEye = () => {
  return (
    <StripeProvider>
      <GoogleOAuthProvider clientId={String(process.env.REACT_APP_CLIENT_ID)}>
        <Provider store={store}>
          <Toaster />
          <RouterProvider />
        </Provider>
      </GoogleOAuthProvider>
    </StripeProvider>
  );
};

export default HawkEye;
