import PrivacyContent from 'components/privacy/privacyContent';
import HeroSection from 'components/contact-us/HeroSection';
import WorkSmarter from 'components/home/WorkSmarter';
import Footer from 'components/shared/Footer';
import NavBar from 'components/shared/NavBar';
import BlurBlue from 'components/style/BlurBlue';

const Privacy = () => {
  function onClickScheduleCall() {
    const contactForm = document.getElementById('contact-form');
    contactForm?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }

  return (
    <div className='w-full mx-auto  overflow-hidden font-Roboto bg-[#F9F9F9] dark:bg-[#1f1f1f]'>
      <BlurBlue />
      <NavBar />
      <PrivacyContent id='PrivacyContent' />
      <WorkSmarter onClickScheduleCall={onClickScheduleCall} />
      <Footer />
    </div>
  );
};

export default Privacy;
