import { StatusEnums } from 'components/shared/EmployeeStatus/types';
import { TActivityType, TGetAllPostCommentParams } from 'store/slices/activitiesSlices/types';
import { TUpdateInvitationParams } from 'store/slices/invitationsSlice/types';

export const endpoints = {
  LandingService: {
    bookDemo: (): string => '/auth-api/he/user/bookings/',
  },
  AuthService: {
    signUp: (): string => `/auth-api/he/user/temp/register/`,
    signIn: (): string => '/auth-api/he/user/temp/login/',
    signInOAuth: (): string => '/auth-api/he/user/oauth/login/',
    registerOauth: (): string => `/auth-api/he/user/oauth/register/`,
    refreshToken: (): string => '/auth-api/he/user/refresh_token/',
    getUserByToken: (): string => '/auth-api/he/user/me/',
    getUserById: (): string => '/auth-api/he/user/id/',
    getAllUsers: (): string => `/auth-api/he/user/users/`,
    getUserPermissions: (): string => '/auth-api/he/user/permissions/',
    searchUserByQuery: (query: string): string => `/auth-api/he/user/search/${query}`,
    updateUser: (): string => '/auth-api/he/user/',
    updateDesignation: (): string => '/auth-api/he/user/designation/',
    updateProfileImage: (): string => '/auth-api/he/user/profile_image/',
    getUserSettings: (): string => '/auth-api/he/user/setting/',
    updateUserSettings: (): string => '/auth-api/he/user/setting/',
    getAllRoles: (): string => '/auth-api/he/roles/roles',
    createRole: (): string => '/auth-api/he/roles/roles',
    getRoleById: (role_id: number): string => `/auth-api/he/roles/roles/${role_id}/`,
    createUserRole: (): string => '/auth-api/he/user_roles/user_roles',
    getAllUserRoles: (): string => '/auth-api/he/user_roles/current_user_roles/',
    deleteUserRole: (user_role_id: number): string => `/auth-api/he/user_roles/${user_role_id}`,
  },
  OrganizationsService: {
    createOrganization: (): string => '/profiles-api/he/organizations/create_organization',
    updateDefaultOrganization: (default_org_id: number): string =>
      `/auth-api/he/user/default_org/?default_org_id=${default_org_id}`,
    updateOrganization: (): string => '/profiles-api/he/organizations/update_organizations/',
    getOrganizationBussinesType: (): string =>
      '/profiles-api/he/organizations/metadata/business_types',
    removeOrganizationMember: (status: string, member_id: number): string =>
      `/profiles-api/he/organizations/members/${member_id}?status=${status}`,
    sendOrganizationResources: (org_id: number): string =>
      `/profiles-api/he/organizations/${org_id}/resources`,
    getUserOrganizations: (): string => '/auth-api/he/user/organizations/',
    updateOrganizationMemberStatus: (member_id: number, status: StatusEnums): string =>
      `/profiles-api/he/organizations/members/${member_id}/status?member_status=${status}`,

    getOrganizationById: (): string => '/profiles-api/sh/organizations/get_organization/id/',
    getAllOrganizations: (): string => '/profiles-api/sh/organizations/get_organization/all/',
    getOrganizationMembers: (): string => `/profiles-api/he/organizations/members`,
    updateOrganizationMember: (): string => '/auth-api/sh/user/edit/',
    // generateApiKey: (): string => '/profiles-api/he/api/',
    generateApiKey: (name: string): string => `/profiles-api/he/api/?name=${name}`,
  },
  SubscriptionsService: {
    createSubscription: (org: any, sub: any, subscription_type?: string): string =>
      `/profiles-api/he/payments/create_subscription?org_id=${org}&subscription=${sub}&subscription_type=${subscription_type}`,
    createPaymentIntent: (): string => '/profiles-api/sh/subscriptions/create_payment_intent',
    setupPaymentIntent: (): string => `/profiles-api/he/payments/create_setup_intent`,
    getUserSubscriptions: (): string => '/profiles-api/he/payments/get_user_subscriptions',
    getPaymentMethods: (): string => '/profiles-api/he/payments/get_payment_methods',
    getDefaultPaymentMethod: (): string => '/profiles-api/he/payments/payment_method/default',
    setDefaultPaymentMethod: (): string => '/profiles-api/he/payments/payment_method/set_default',
    removePaymentMethod: (): string => '/profiles-api/he/payments/detach_payment_method',
    updateBillingDetails: (): string => '/profiles-api/he/payments/payment_method/billing_details',
    getInvoiceHistory: (): string => '/profiles-api/he/payments/get_invoice_history',
    updateSubscription: (): string => '/profiles-api/he/payments/update_subscription',
  },
  RolesService: {
    getAllRoles: (): string => '/auth-api/he/roles/roles',
  },
  InvitationService: {
    createInvitation: (): string => '/auth-api/he/invited_user/create/',
    invitedUser: (parmas: number | null | string): string =>
      `/auth-api/he/invited_user/create/${parmas && `?org_id=${parmas}`}`,
    getInvitationByOrgId: (): string => `/auth-api/sh/invited_user/invitations/`,
    invitedPendingUsers: (): string => '/auth-api/sh/invited_user/pending-invitations/',
    getInvitationById: (id: number): string => `/auth-api/he/invited_user/invitations/${id}`,
    getInvitations: (): string => `/auth-api/he/invited_user/invitations/`,
    getUserInvitations: (): string => `/auth-api/he/invited_user/user_invitations/`,
    updateInvitationStatus: ({ id, status }: TUpdateInvitationParams): string =>
      `/auth-api/he/invited_user/status?id=${id}&status=${status}`,
    updateEmailInvitationStatus: (): string => '/auth-api/he/invited_user/from_email/status',
    deactivateUser: (): string => '/auth-api/he/user/member/deactivate/',
    reactivateUser: (): string => '/auth-api/he/user/member/reactivate/',
  },
  ActivityService: {
    getActivePosts: (): string => `/activities-api/he/posts/`,
    updatePostById: (id: number): string => `/activities-api/he/posts/${id}`,
    createPost: (): string => `/activities-api/he/posts/`,
    postActivityLike: (): string => `/activities-api/he/likes/like`,
    getActivityLikes: (activityType: TActivityType, activityId: number): string =>
      `/activities-api/he/likes/${activityType}/${activityId}`,
    removeActivityLike: (): string => `/activities-api/he/likes/`,
    deletePostById: (activity_feed_id: number): string =>
      `/activities-api/he/posts/${activity_feed_id}`,

    getActivities: (): string => '/activities-api/sh/activity_feed/',
    getFilterActivities: (id: number): string => `/activities-api/sh/activity_feed/${id}`,
    getActivityById: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/${activity_feed_id}`,
    getActivityByUserId: (user_id: number): string =>
      `/activities-api/sh/activity_feed/user/${user_id}`,
    getActivityByQuery: (query: string): string =>
      `/activities-api/sh/activity_feed/search/${query}`,
    getActivityComment: (params: TGetAllPostCommentParams): string =>
      `/activities-api/he/comments/post/${params?.id}/?limit=${params?.limit}&offset=${params?.offset}`,
    createActivityComment: (): string => `/activities-api/he/comments/`,
    getActivityReplies: (comment_id: number): string =>
      `/activities-api/sh/activity_feed/comment/${comment_id}/replies`,
    createActivityFlag: (): string => '/activities-api/sh/activity_feed/flag',
    getActivityFlags: (id: number): string => `/activities-api/sh/activity_feed/flag/${id}`,
    disableActivity: (activity_feed_id: number): string =>
      `/activities-api/sh/activity_feed/disable/${activity_feed_id}`,
    getDisabledActivity: (): string => '/activities-api/sh/activity_feed/disable/',
    updatePostComment: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}`,
    removePostComment: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}`,
    likePostComment: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}/like`,
    removePostCommentLike: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/like/${comment_id}`,
    getNotices: (): string => '/activities-api/sh/notice/',
    updateNotice: (): string => '/activities-api/sh/notice/',
    createNotice: (): string => '/activities-api/sh/notice/',
    getNoticeById: (notice_id: number): string => `/activities-api/sh/notice/${notice_id}`,
    deleteNoticeById: (notice_id: number): string => `/activities-api/sh/notice/${notice_id}/`,
    getPostCommentById: (comment_id: number): string =>
      `/activities-api/sh/activity_comments/${comment_id}`,
    hidePost: (activity_feed_id: number): string =>
      `/activities-api/sh/hidden_activities/${activity_feed_id}/`,
    getHiddenActivityById: (id: number): string => `/activities-api/sh/hidden_activities/${id}`,
    removeHiddenActivity: (id: number): string => `/activities-api/sh/hidden_activities/${id}`,
    getAllHiddenActivityFeeds: (): string => `/activities-api/sh/hidden_activities/`,
    getAllNotifications: (): string => `/activities-api/he/notifications/`,
    getNews: (): string => '/activities-api/he/news/',
    askAIAgent: (): string => '/activities-api/he/posts/query/',
  },
  AnalyticsService: {
    getCurrentHeadCount: (): string => '/analytics-api/he/analytics/get_current_headcount',
    getSumHeadCount: (deviceId: number | undefined, days: number): string =>
      `/analytics-api/he/analytics/get_sum_headcount/days?days=${days}${
        deviceId ? '&device_id=' + deviceId : ''
      }`,
    getHeadCountAtIntervals: (): string =>
      '/analytics-api/he/analytics/get_sum_headcount/hours/interval',
    getSeatingDensity: (): string => '/analytics-api/he/analytics/seating_density',
    getTotalHeadCountReport: (days: number, deviceId?: number): string =>
      `/analytics-api/he/analytics/get_sum_headcount/days/csv?days=${days}${
        deviceId ? '&device_id=' + deviceId : ''
      }`,
    getHeadCountIntervalReport: (): string =>
      '/analytics-api/he/analytics/get_sum_headcount/hours/interval/csv',
    getAnalyticsByDatetime: (): string => '/analytics-api/he/analytics/get_analytics/datetime',
  },
  DevicesService: {
    getDevices: (): string => '/devices-api/he/devices/',
    editDevices: (id: number): string => `/devices-api/he/devices/${id}`,
    deviceHistoryById: (id: number | 0): string =>
      `/devices-api/he/devices/history/device/${id || 0}`,
    getDevicesVertices: (): string => `/devices-api/he/devices/device-roi`,
    devicesConfig: (id: number): string => `/devices-api/he/devices/${id}/device-config/`,
    getDevicesCounts: (): string => `/devices-api/he/devices/count`,
    startStream: (device_id: number): string =>
      `/devices-api/he/stream/start-stream?device_id=${device_id}`,
    endStream: (device_id: number): string => `/devices-api/he/stream/end/?device_id=${device_id}`,
    updateDevice: (id: number): string => `/devices-api/he/devices/device_id=${id}`,
    getDeviceVertices: (): string => `/devices-api/he/devices/device-roi/`,
    deleteDeviceVertices: (device_id: number, id: number): string =>
      `/devices-api/he/devices/${device_id}/roi/${id}`,
    editDevicesVertices: (): string =>
      `/devices-api/he/devices/device-roi/`,
  },
  EventsService: {
    getEventCounts: (): string => '/events-api/he/events/event/count',
    getAllEvents: (): string => '/events-api/he/events/event',
    getCurrentEvents: (): string => '/events-api/he/events/event/current',
    postEvent: (): string => '/events-api/he/events/event',
    getEventsData: (): string => '/events-api/he/events/event_data',
    getEventById: (id: number): string => `/events-api/he/events/event/${id}`,
    //getEventById: (id: number): string => `/events-api/he/events/event/${id}?upcoming=true`,
    editDevice: (): string => '/events-api/he/events/event',
    updateEvent: (): string => '/events-api/he/events/event',
  },
  TeamsService: {
    getTeam: (): string => '/teams-api/he/teams/get_team/id/',
    getTeamMember: (): string => '/teams-api/he/teams/get_team_member/id',
    getAllTeams: (): string => '/teams-api/he/teams/get_all_teams',
    getAllTeamMembers: (): string => '/teams-api/he/teams/get_all_team_members',
    createTeam: (params: any): string =>
      `/teams-api/he/teams/create_team?avaliabilty=${params?.avaliability}&contact_info=${params?.contact_info}&position=${params?.position}`,
    createTeamMember: (): string => '/teams-api/he/teams/create_team_member',
    getTeamMemberbyID: (): string => '/teams-api/he/teams/get_team_member/id',
    deleteTeamMemberbyID: (): string => '/teams-api/he/teams/delete_team_member/id',
    getTeambyID: (): string => '/teams-api/he/teams/get_team/id/',
    editTeamMember: (): string => '/teams-api/he/teams/update_team_member',
    deleteTeam: (): string => '/teams-api/he/teams/delete_team',
    updateTeam: (): string => '/teams-api/he/teams/update_team',
  },
  MediaService: {
    getSignedMediaUrl: (): string => `/media-api/he/media/signed-url`,
    getSignedMediaUrls: (): string => `/media-api/he/media/signed-url/batch`,
  },
  WeatherService: {
    getWeather: (): string => `https://api.openweathermap.org/data/3.0/onecall`,
    getLocationCoordinates: (): string => `https://api.openweathermap.org/geo/1.0/direct`,
  },
  AppleAuthService: {
    getSignInWithApple: (client_id: string, redirect_uri: string): string =>
      `https://appleid.apple.com/auth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&response_mode=fragment`,
  },
  ContactUsService: {
    postContact: (): string => `/auth-api/he/contact_us/`,
  },

  ChatbotService: {
    getChatbotMessages: (): string => `/activities-api/he/chatbot/`,
    createChatbotMessage: (): string => `/activities-api/he/chatbot/`,
  },
};