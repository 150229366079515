import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import paths from 'constants/paths';

import Container from './Container';

const footerLinks = {
  resources: [
    { name: 'Privacy', href: paths.PRIVACY_PAGE },
    { name: 'Terms and Conditions', href: paths.TERMS_PAGE },
  ],
  company: [{ name: 'Contact Us', href: paths.CONTACT_US }],
  industries: [
    { name: 'Retails', href: paths.INDUSTRIES_PAGE },
    { name: 'Hospitality', href: paths.HOSPITALITY_PAGE },
    { name: 'Congregation', href: paths.MINISTRY_PAGE },
    { name: 'Transport', href: paths.TRANSPORT_PAGE },
    { name: 'Construction', href: paths.CONSTRUCTION_PAGE },
    { name: 'Production', href: paths.PRODUCTION_PAGE },
    { name: 'Events', href: paths.EVENTS_PAGE },
  ],
};

type FooterLink = {
  name: string;
  href: string;
};
const FooterNav = ({ title, links }: { title: string; links: FooterLink[] }) => {

  return (
    <div className='flex flex-col gap-5'>
      <h3 className='font-bold text-[12px] uppercase'>{title}</h3>
      <div className='flex flex-col gap-2'>
        {links.map((link) => (
          <Link
            key={link.name}
            to={link.href}
            className='text-[14px] hover:underline cursor-pointer text-footer-text'
          >
            {link.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className='w-full bg-footer-bg  pt-[200px] relative text-white pb-0 overflow-hidden'>
      <Container>
        <div className='flex flex-col md:flex-row gap-10 md:gap-[65px] justify-between'>
          <div className='flex flex-col md:flex-row gap-10 md:gap-[65px] justify-between w-full'>
            <div className='flex flex-col'>
              <img
                src='/images/hawk_white_logo.png'
                className='w-[198px]'
                alt='HawkEyeVisionTek logo'
              />
              <p className='text-[13px] mt-8 text-footer-text  '>
                Experience the future of vision enhancement, tailored to perfection for clarity and
                accuracy beyond the visible spectrum.
              </p>
            </div>

            <FooterNav title='Resources' links={footerLinks.resources} />
            <FooterNav title='Company' links={footerLinks.company} />
            <FooterNav title='Industries' links={footerLinks.industries} />
          </div>
        </div>
      </Container>
      <div className='w-full bg-footer-bg mt-[50px]'>
        <Container className='py-7'>
          <div className='flex flex-col md:flex-row gap-4 md:items-center justify-between '>
            <p className='text-footer-text text-[12px] lg:text-right text-center'>
              Copyright {new Date().getFullYear()} HawkEyeVisionTek LLC. All Rights Reserved.
            </p>
            <div className='flex items-center gap-4 lg:justify-start justify-center '>
              <Link
                to= {paths.TERMS_PAGE }
                className='cursor-pointer text-footer-text text-[12px] border-r border-footer-text pr-4'
              >
                Terms & Conditions
              </Link>
              <Link to={paths.PRIVACY_PAGE } className='cursor-pointer  text-footer-text text-[12px] '>
                Privacy Policy
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default Footer;
