import { AxiosResponse } from 'axios';

import { client } from 'api/client';
import { endpoints } from 'api/endpoints';
import {
  TAddNewDevicesParams,
  TDeviceHistoryParams,
  TDeviceHistoryResponse,
  TDeviceParams,
  TDevicesBoundaryEdit,
  TDevicesConfig,
  TDevicesCountsResponse,
  TDevicesParams,
  TDevicesResponse,
  TEditwDevicesParams,
  TStreamResponse,
  TUpdateDeviceParams,
  TUpdateDeviceResponse,
  TDeleteDeviceVertices,
  TUpdateDeviceVerticesParam,
} from 'store/slices/devicesSlice/type';

export const getDevicesRequest = (
  params?: TDevicesParams,
): Promise<AxiosResponse<TDevicesResponse>> => {
  return client.get(endpoints.DevicesService.getDevices(), {
    params: params ?? {},
  });
};

export const getDeviceByIdRequest = (
  params: TDeviceParams,
): Promise<AxiosResponse<TDevicesResponse>> => {
  return client.get(endpoints.DevicesService.getDevices(), {
    params,
  });
};

export const AddNewDevicesRequest = (body: TAddNewDevicesParams) => {
  return client.post(endpoints.DevicesService.getDevices(), body);
};

export const EditDevicesRequest = (body: TEditwDevicesParams) => {
  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  };
  return client.put(endpoints.DevicesService.editDevices(body.id), body, options);
};

export const deviceHistoryById = ({
  limit,
  offset,
  id,
}: TDeviceHistoryParams): Promise<AxiosResponse<TDeviceHistoryResponse>> => {
  return client.get(endpoints.DevicesService.deviceHistoryById(id || 0), {
    params: { limit, offset },
  });
};

export const getDeviceRoi = (body: TDevicesBoundaryEdit): Promise<AxiosResponse<any>> => {
  return client.post(endpoints.DevicesService.getDevicesVertices(), body);
};

export const devicesConfigRequest = (body: TDevicesConfig) => {
  const params = {
    pan: body.pan || 0,
    tilt: body.tilt || 0,
  };
  return client.put(endpoints.DevicesService.devicesConfig(body.id), params);
};

export const getDevicesCountsRequest = (): Promise<AxiosResponse<TDevicesCountsResponse>> =>
  client.get(endpoints.DevicesService.getDevicesCounts());

export const startStreamRequest = (
  device_id: number,
): Promise<AxiosResponse<TStreamResponse[]>> => {
  return client.post(endpoints.DevicesService.startStream(device_id));
};

export const endStreamRequest = (device_id: number): Promise<AxiosResponse<TStreamResponse[]>> => {
  return client.delete(endpoints.DevicesService.endStream(device_id));
};

export const updateDeviceRequest = (
  params: TUpdateDeviceParams,
): Promise<AxiosResponse<TUpdateDeviceResponse>> => {
  return client.put(endpoints.DevicesService.updateDevice(params.id), params);
};

export const getDevicesVertices = ({
  device_id,
  limit,
  offset,
}: {
  device_id: number;
  limit?: number;
  offset?: number;
}): Promise<AxiosResponse<any>> => {
  return client.get(endpoints.DevicesService.getDeviceVertices(), {
    params: {
      device_id,
      limit,
      offset,
    },
  });
};

export const deleteDeviceVertices = (
  device_id: number,
  id: number,
): Promise<AxiosResponse<TDeleteDeviceVertices>> => {
  return client.delete(endpoints.DevicesService.deleteDeviceVertices(device_id, id));
};

export const EditDevicesVertices = (body: TUpdateDeviceVerticesParam) => {
  return client.put(endpoints.DevicesService.editDevicesVertices(), body);
};