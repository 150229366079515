import React from 'react';
import Container from 'components/shared/Container';
import { 
  AgreementToOurLegalTerms, 
  OurServices, 
  IntellectualPropertyRights, 
  UserRepresentations,
  UserRegistration,
  Products,
  PurchasesAndPayment,
  Subscriptions,
  ReturnPolicy,
  ServicesManagement,
  PrivacyPolicy,
  CopyrightInfringements,
  TermAndTermination,
  ModificationsAndInterruptions,
  GoverningLaw,
  ProhibitedActivities,
  UserGeneratedContributions,
  ContributionLicense,
  DisputeResolution,
   Corrections,
  Disclaimer,
  LimitationsOfLiability,
  Indemnification,
  UserData,
  ElectronicCommunications,
  CaliforniaUsers,
  Miscellaneous,
  ProductInstallation,
  LimitedProductWarranty,
  ContactUs
} from './Headings'; 

const tableOfContents = [
  { title: "OUR SERVICES", id: "our-services" },
  { title: "INTELLECTUAL PROPERTY RIGHTS", id: "intellectual-property-rights" },
  { title: "USER REPRESENTATIONS", id: "user-representations" },
  { title: "USER REGISTRATION", id: "user-registration" },
  { title: "PRODUCTS", id: "products" },
  { title: "PURCHASES AND PAYMENT", id: "purchases-and-payment" },
  { title: "SUBSCRIPTIONS", id: "subscriptions" },
  { title: "RETURN POLICY", id: "return-policy" },
  { title: "PROHIBITED ACTIVITIES", id: "prohibited-activities" },
  { title: "USER GENERATED CONTRIBUTIONS", id: "user-generated-contributions" },
  { title: "CONTRIBUTION LICENSE", id: "contribution-license" },
  { title: "SERVICES MANAGEMENT", id: "services-management" },
  { title: "PRIVACY POLICY", id: "privacy-policy" },
  { title: "COPYRIGHT INFRINGEMENTS", id: "copyright-infringements" },
  { title: "TERM AND TERMINATION", id: "term-and-termination" },
  { title: "MODIFICATIONS AND INTERRUPTIONS", id: "modifications-and-interruptions" },
  { title: "GOVERNING LAW", id: "governing-law" },
  { title: "DISPUTE RESOLUTION", id: "dispute-resolution" },
  { title: "CORRECTIONS", id: "corrections" },
  { title: "DISCLAIMER", id: "disclaimer" },
  { title: "LIMITATIONS OF LIABILITY", id: "limitations-of-liability" },
  { title: "INDEMNIFICATION", id: "indemnification" },
  { title: "USER DATA", id: "user-data" },
  { title: "ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES", id: "electronic-communications" },
  { title: "CALIFORNIA USERS AND RESIDENTS", id: "california-users" },
  { title: "MISCELLANEOUS", id: "miscellaneous" },
  { title: "PRODUCT INSTALLATION", id: "product-installation" },
  { title: "LIMITED PRODUCT WARRANTY", id: "limited-product-warranty" },
  { title: "CONTACT US", id: "contact-us" },
];

const LegalTermsPage: React.FC = () => {

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    } else {
      console.error(`Section with ID ${id} not found.`);
    }
  };

  return (
    <div className="mt-10">
      <Container className="my-20">
        <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-5 mt-10">
            <h1 className="text-[30px] font-[900] font-Avenir-Next-Pro " style={{ color: "#0054AA" }}>
            HawkeyeVisionTek Terms and Condition
            </h1>
            <p className="text-base md:text-[18px] font-[900] ">
              <strong>Last updated:</strong> January 12, 2025
            </p>
          </div>
          {/* Agreement Section First */}
          <AgreementToOurLegalTerms />

          <div className="py-4">
            <h2 className="text-2xl font-semibold">TABLE OF CONTENTS</h2>
            <ul className="list-none pl-0">
              {tableOfContents.map((tocItem, index) => (
                <li key={index} className="py-1">
                  <button
                    className="text-lg text-blue-600 hover:underline"
                    onClick={() => scrollToSection(tocItem.id)}
                  >
                    {tocItem.title}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <OurServices />
          <IntellectualPropertyRights />
          <UserRepresentations />
          <UserRegistration />
          <Products />
          <PurchasesAndPayment />
          <Subscriptions />
          <ReturnPolicy />
          <ProhibitedActivities />
          <UserGeneratedContributions />
          <ContributionLicense />
          <ServicesManagement />
          <PrivacyPolicy />
          <CopyrightInfringements />
          <TermAndTermination />
          <ModificationsAndInterruptions />
          <GoverningLaw />
          <DisputeResolution />
          <Corrections />
          <Disclaimer />
          <LimitationsOfLiability />
          <Indemnification />
          <UserData />
          <ElectronicCommunications />
          <CaliforniaUsers />
          <Miscellaneous />
          <ProductInstallation />
          <LimitedProductWarranty />
          <ContactUs />
        </div>
      </Container>
    </div>
  );
};

export default LegalTermsPage;
