import React, { useState, useEffect } from 'react';
import { ArrowLongUpIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { DeviceCardSkeleton } from 'components/skeleton';
import { useNavigate } from 'react-router-dom';

interface CallToActionProps {
  image: string;
  title: string;
  description: string;
  className?: string;
  isBg?: boolean;
  path?: string;
}

const CallToAction: React.FC<CallToActionProps> = ({
  image,
  title,
  description,
  className,
  isBg,
  path,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const navigate = useNavigate();
  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (isBg) {
    return (
      <div
        className={`w-[300px] h-[400px] center-child flex-col p-5  gap-y-6 rounded-[25px] bg-[#F0F7FF] dark:bg-[#1a1818] border, ${className}`}
      >
        <div className='h-[110px] w-[110px] rounded-full bg-[#DFEDFF] dark:bg-[#2B2B2B] center-child'>
          <img src={image} alt='' className='h-[50px] w-[50px]' />
        </div>
        <h6 className='text-[16px] text-center font-semibold font-Avenir-Next-Pro'>{title}</h6>
        <p className='text-[12px] text-center font-light'>{description}</p>
      </div>
    );
  }
  return (
    <div
      className={`w-[400px] center-child flex-col py-5 px-2 gap-y-6 rounded-[40px] , ${className}`}
    >
      {isLoading ? (
        <div className='w-[360px] h-[218px] rounded-[20px] bg-gray-200 animate-pulse'></div>
      ) : (
        <img
          src={image}
          alt=''
          className={`w-[360px] h-[218px] rounded-[20px] transition-opacity duration-1000  ${
            isImageLoaded ? 'opacity-100' : 'opacity-0'
          }`}
          onLoad={handleImageLoad}
        />
      )}

      <h6 className='text-[20px] text-center font-semibold font-Avenir-Next-Pro'>{title}</h6>
      <p className='text-[15px] text-center font-light'>{description}</p>
      <button
        type='button'
        className='btn-primary border border-accent dark:border-white w-[80%] text-accent dark:text-white group'
        onClick={() =>
          navigate(path ?? '', {
            state: { fromHome: true },
          })
        }
      >
        Learn More
        <ArrowLongUpIcon className='h-6 w-6 dark:text-white text-accent !rotate-[45deg] group-hover:!rotate-[55deg]  ml-4' />
      </button>
    </div>
  );
};
CallToAction.defaultProps = {
  className: '',
  isBg: false,
  path: '',
};

export default CallToAction;