import Terms from 'containers/Website/Terms';
import { Home, EventsSection, ContactUs, NotFound, IndustryLayout } from '../containers';
import { RoutesProps, Routes } from '../types';
import Privacy from 'containers/Website/Privacy';

const routes: RoutesProps[] = [
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Landing,
    isProtected: false,
    component: <Home />,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Error,
    isProtected: false,
    component: <span className='grid place-content-center py-20'>Unknown error occured</span>,
    withLogoHeader: true,
  },
  {
    isLanding: true,
    withNavbar: false,
    path: Routes.NotFound,
    isProtected: false,
    component: <NotFound />,
    withLogoHeader: true,
  },

  {
    isLanding: true,
    withNavbar: false,
    path: Routes.Error,
    isProtected: false,
    component: <Home />,
    withLogoHeader: true,
  },

  {
    isLanding: true,
    path: Routes.Industriespage,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Transport,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Construction,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Hospitality,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Ministries,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },
  {
    isLanding: true,
    path: Routes.Production,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <IndustryLayout />,
  },

  {
    isLanding: true,
    path: Routes.ContactUs,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <ContactUs />,
  },
  {
    isLanding: true,
    path: Routes.Privacy,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <Privacy/>,
  },
  {
    isLanding: true,
    path: Routes.Terms,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: <Terms />,
  },
  {
    isLanding: true,
    path: Routes.Events,
    withLogoHeader: true,
    isProtected: false,
    withNavbar: true,
    component: (
      <IndustryLayout>
        <EventsSection />
      </IndustryLayout>
    ),
  },
];

export default routes;
