import React, { forwardRef, useState } from 'react';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';

import { Colors, FontNames, type TChangeInputEvent } from 'types';
import { useDarkMode } from 'hooks';

import ErrorMessage from '../ErrorMessage';

import { customStyleCreator } from './styles';
import { TCustomTextFieldProps } from './types';

const CustomTextField: React.FC<TCustomTextFieldProps> = forwardRef<
  HTMLInputElement,
  TCustomTextFieldProps
>(
  (
    {
      id,
      maxLength,
      autoComplete = 'off',
      label,
      color,
      error,
      rows,
      name,
      value,
      asText,
      height,
      onBlur,
      onFocus,
      focused,
      warning,
      padding,
      maxWidth,
      inputRef,
      tabIndex,
      leftIcon,
      disabled,
      children,
      multiline,
      className,
      onChange,
      variant,
      rightIcon,
      inputProps,
      iconPadding,
      defaultValue,
      borderWidth,
      placeholder,
      backGroundColor,
      fromRequisition,
      autoFocus = false,
      readonly = false,
      required = false,
      containerClasses,
      placeHolderColor,
      borderRadius = '5px',
      onEndAdornmentClick,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      fromPayment = false,
      onStartAdornmentClick,
      withHelperText = true,
      isFromAutoComplete = false,
      borderColor = 'transparent',
      labelColor,
      fontFamily = FontNames.AVENIR_NEXT_PRO,
      ...rest
    },
    ref,
  ) => {
    const [inputValue, setInputValue] = useState<string>('');
    const { darkMode } = useDarkMode();

    const customStyle = customStyleCreator({
      error,
      warning,
      borderColor,
      color: color as string,
      height: height as string,
      padding: padding as string,
      fontFamily: fontFamily as string,
      borderWidth: borderWidth as string,
      iconPadding: iconPadding as string,
      borderRadius: borderRadius as string,
      backGroundColor: backGroundColor as string,
      placeHolderColor: placeHolderColor as string,
    });

    const containerStyle: React.CSSProperties = {
      maxWidth: maxWidth || '100%',
      width: '100%',
      position: 'relative',
    };

    const helperTextStyle: React.CSSProperties = {
      color: warning ? '#FFCC00' : '#FF3333',
      display: 'none',
    };

    const handleChange = (event: TChangeInputEvent) => {
      if (onChange) {
        onChange(event);
      } else {
        setInputValue(event.target.value);
      }
    };

    const renderBorder = () => {
      if (!isFromAutoComplete) {
        if (!leftIcon || !rightIcon) {
          `1px solid ${borderColor}`;
        }
        return '';
      }
      return '';
    };

    return (
      <div style={containerClasses ? undefined : containerStyle} className={containerClasses}>
        {withHelperText && (error || warning) && (
          <ErrorMessage message={rest?.helperText as string} />
        )}
        <TextField
          id={id ? String(id) : undefined}
          inputRef={inputRef}
          autoComplete={autoComplete}
          label={label}
          onBlur={onBlur}
          onFocus={onFocus}
          sx={{
            ...customStyle,

            ' input': {
              fontSize: '16px',
              borderRadius: borderRadius,
              cursor: disabled ? 'not-allowed !important' : asText ? 'default' : 'text',
              border: renderBorder(),
            },
            ' textarea': {
              fontSize: '16px',
              borderRadius: borderRadius,
              cursor: disabled ? 'not-allowed' : asText ? 'default' : 'text',  
            },
            ' .MuiFormControl-root': {
              padding: padding,            
            },
            ' .MuiInputBase-root': {
              padding: fromRequisition ? padding : '',
              ...(color ? { color } : { color: darkMode ? Colors.WHITE : '' }),
              ...(backGroundColor
                ? { backgroundColor: backGroundColor }
                : { backgroundColor: darkMode ? Colors.DARK_MODE_BG : Colors.LIGHT_SILVER }),
            },

            fieldset: {
              border: error
                ? '1px solid red !important'
                : darkMode
                ? '1px solid #6b7280 !important'
                : '1px solid #EDEFF1',
              borderWidth: '1px !important',
            },

            label: {
              fontFamily: `${fontFamily} !important`,
              color: darkMode ? Colors.WHITE : Colors.ENIGMATIC_MIDNIGHT,
            },

            '&:hover fieldset': {
              borderColor: darkMode ? '#ffffff !important' : '#2067dd !important',
            },
            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled': {
              '-webkit-text-fill-color': darkMode ? '#ffffff !important' : ' ',
            },

            '& .MuiOutlinedInput-input:focus': {
              borderColor: 'green !important',
              '&:focus-within': {
                outline: 'none !important',
              },
            },
          }}
          InputLabelProps={{
            style: {
              fontFamily: 'CircularStdLight',
              color: darkMode ? Colors.WHITE : Colors.ENIGMATIC_MIDNIGHT,
            },
          }}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          multiline={multiline}
          autoFocus={autoFocus || !!error}
          className={className}
          error={error}
          name={name}
          rows={rows}
          variant={variant}
          focused={focused}
          tabIndex={tabIndex}
          value={value || inputValue}
          onChange={handleChange}
          defaultValue={defaultValue}
          helperText={(withHelperText && error) || warning ? 'Incorrect entry.' : ''}
          FormHelperTextProps={{ style: helperTextStyle }}
          InputProps={{
            readOnly: readonly,
            sx: {
              borderRadius: borderRadius,
              cursor: 'pointer',
            },
            startAdornment: leftIcon && (
              <InputAdornment position='start' onClick={onStartAdornmentClick}>
                {leftIcon}
              </InputAdornment>
            ),
            endAdornment: rightIcon && (
              <InputAdornment position='end' onClick={onEndAdornmentClick}>
                {rightIcon}
              </InputAdornment>
            ),
          }}
          {...rest}
          inputProps={{
            ...inputProps,
            maxLength, 
          }}
          ref={ref}
        />
        {children}
      </div>
    );
  },
);

export default CustomTextField;
