import React from 'react';
const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    } else {
      console.error(`Section with ID ${id} not found.`);
    }
  };
export const PrivacyNotice: React.FC = () => (
    <div id="privacy-notice" className="py-4 font-Avenir-Next-Pro">
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        This Privacy Notice for HawkeyeVisionTek LLC (<span className="italic">&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;</span>), describes how and why we might access, collect, store, use, and/or share (<span className="italic">&quot;process&quot;</span>) your personal information when you use our services (<span className="italic">&quot;Services&quot;</span>), including when you:
      </p>
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>
          Visit our website at{" "}
          <a href="https://hawkeyevisiontek.com" className="text-blue-600 underline">
            https://hawkeyevisiontek.com
          </a>
          , or any website of ours that links to this Privacy Notice.
        </li>
        <li>
          Use HawkeyeVisionTek LLC’s revolutionary AI-powered technology, delivering unparalleled precision, clarity, and insight across various industries and applications at scale.
        </li>
        <li>
          Engage with us in other related ways, including any sales, marketing, or events.
        </li>
      </ul>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>Questions or concerns?</strong> Reading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at{" "}
        <a href="mailto:support@hawkeyevisiontek.com" className="text-blue-600 underline">
          support@hawkeyevisiontek.com
        </a>
        .
      </p>
    </div>
  );

export const SummaryOfKeyPoints: React.FC = () => {

  return (
    <div id="summary-of-key-points" className="py-4 font-Avenir-Next-Pro">
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our
          <button 
            onClick={() => scrollToSection('table-of-contents')}
            className="text-blue-600 underline focus:outline-none px-1"
          >
           table of contents  {" "}
          </button>
         below to find the section you are looking for.
      </p>
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>
          <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use.{" "}
          <button 
            onClick={() => scrollToSection('what-information-we-collect')}
            className="text-blue-600 underline focus:outline-none"
          >
            Learn more about personal information you disclose to us.
          </button>
        </li>
        <li>
          <strong>Do we process any sensitive personal information?</strong> Some of the information may be considered &quot;special&quot; or &quot;sensitive&quot; in certain jurisdictions, for example, your racial or ethnic origins, sexual orientation, and religious beliefs. We do not process sensitive personal information.
        </li>
        <li>
          <strong>Do we collect any information from third parties?</strong> We do not collect any information from third parties.
        </li>
        <li>
          <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.{" "}
          <button 
            onClick={() => scrollToSection('how-we-process-your-information')}
            className="text-blue-600 underline focus:outline-none"
          >
            Learn more about how we process your information.
          </button>
        </li>
        <li>
          <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and with specific third parties.{" "}
          <button 
            onClick={() => scrollToSection('sharing-personal-information')}
            className="text-blue-600 underline focus:outline-none"
          >
            Learn more about when and with whom we share your personal information.
          </button>
        </li>
        <li>
          <strong>How do we keep your information safe?</strong> We have adequate organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.{" "}
          <button 
            onClick={() => scrollToSection('how-do-we-keep-your-information-safe')}
            className="text-blue-600 underline focus:outline-none"
          >
            Learn more about how we keep your information safe.
          </button>
        </li>
        <li>
          <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.{" "}
          <button 
            onClick={() => scrollToSection('what-are-your-privacy-rights')}
            className="text-blue-600 underline focus:outline-none"
          >
            Learn more about your privacy rights.
          </button>
        </li>
        <li>
          <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
        </li>
        <li>
          <strong>Want to learn more about what we do with any information we collect?</strong> Review the{" "}
          <button 
            onClick={() => scrollToSection('table-of-contents')}
            className="text-blue-600 underline focus:outline-none"
          >
            Privacy Notice in full.
          </button>
        </li>
      </ul>
    </div>
  );
};
export const WhatInformationWeCollect: React.FC = () => (
    <div id="what-information-we-collect" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">1.WHAT INFORMATION DO WE COLLECT?</h2>
      
      <h3 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Personal information you disclose to us</h3>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        <strong>In Short:</strong> We collect personal information that you provide to us.
      </p>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
      </p>
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Personal Information Provided by You</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
      </p>
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>Names</li>
        <li>Phone numbers</li>
        <li>Email addresses</li>
        <li>Mailing addresses</li>
        <li>Job titles</li>
        <li>Contact preferences</li>
        <li>Billing addresses</li>
      </ul>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>Sensitive Information:</strong> We do not process sensitive information.
      </p>
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Payment Data</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by Stripe. You may find their privacy notice link(s) here: <a href="https://stripe.com/privacy" className="text-blue-600 underline">https://stripe.com/privacy</a>.
      </p>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
      </p>
      
      <h3 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Information automatically collected</h3>
      <p className="text-lg text-gray-700 dark:text-white  leading-relaxed">
        <strong>In Short:</strong> Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
      </p>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
      </p>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Like many businesses, we also collect information through cookies and similar technologies.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">The information we collect includes:</h4>
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      <li>
    <strong>Device Data:</strong> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
    </li>
    <li>
    <strong>Location Data:</strong> We collect location data such as information about your device&#39;s location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
    </li>
    <li><strong>Quantification Data:</strong> Number of people in attendance.</li>
    </ul>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Google API</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Our use of information received from Google APIs will adhere to the 
        <a 
            href="https://developers.google.com/terms/api-services-user-data-policy" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:underline px-1"
        >
            Google API Services User Data Policy
        </a>, 
        including the 
        <a 
            href="https://developers.google.com/terms/terms/api-services-user-data-policy#limited-use" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:underline px-1"
        >
            Limited Use requirements
        </a>.
        </p>

      </p>
    </div>
  );
  export const HowWeProcessYourInformation: React.FC = () => (
    <div id="how-we-process-your-information" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
      </p>
      
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
      </p>
      
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">To facilitate account creation and authentication</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        We may process your information so you can create and log in to your account, as well as keep your account in working order.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">To deliver and facilitate delivery of services to the user</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        We may process your information to provide you with the requested service.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">To identify usage trends</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        We may process information about how you use our Services to better understand how they are being used so we can improve them.
      </p>
    </div>
  );
  
  export const LegalBasesForProcessingInformation: React.FC = () => (
    <div id="legal-bases-for-processing-information" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        If you are located in Canada, this section applies to you.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e., implied consent). You can 
        <button 
            onClick={() => scrollToSection('what-are-your-privacy-rights')}
            className="text-blue-600 underline focus:outline-none px-1"
        >
            withdraw your consent
        </button> 
        at any time.
        </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
      </p>
  
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
        <li>For investigations and fraud detection and prevention</li>
        <li>For business transactions provided certain conditions are met</li>
        <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
        <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
        <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
        <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
        <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
        <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
        <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
        <li>If the information is publicly available and is specified by the regulations</li>
      </ul>
    </div>
  );
  export const SharingPersonalInformation: React.FC = () => (
    <div id="sharing-personal-information" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may need to share your personal information in the following situations:
      </p>
  
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>
          <strong>Business Transfers:</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
        </li>
        <li>
          <strong>When we use Google Maps Platform APIs:</strong> We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and cell towers to estimate your location. GPS is accurate to about 20 meters, while Wi-Fi and cell towers help improve accuracy when GPS signals are weak, like indoors. This data helps Google Maps provide directions, but it is not always perfectly precise. We obtain and store on your device (&#34;cache&#34;) your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
        </li>
        <li>
          <strong>Business Partners:</strong> We may share your information with our business partners to offer you certain products, services, or promotions.
        </li>
      </ul>
    </div>
  );
  export const TrackingTechnologies: React.FC = () => (
    <div id="tracking-technologies" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        To the extent these online tracking technologies are deemed to be a &#34;sale&#34;/&#34;sharing&#34; (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section 
        &#34;<button 
        onClick={() => scrollToSection('us-residents-privacy-rights')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
        DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </button>&#34;
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
      </p>
    </div>
  );
  export const ArtificialIntelligenceProducts: React.FC = () => (
    <div id="artificial-intelligence-products" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        As part of our Services, we offer products, features, or tools powered by artificial intelligence, machine learning, or similar technologies (collectively, &quot;AI Products&quot;). These tools are designed to enhance your experience and provide you with innovative solutions. The terms in this Privacy Notice govern your use of the AI Products within our Services.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Use of AI Technologies</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        We provide the AI Products through third-party service providers (&quot;AI Service Providers&quot;), including Google Cloud AI. As outlined in this Privacy Notice, your input, output, and personal information will be shared with and processed by these AI Service Providers to enable your use of our AI Products for purposes outlined in 
        &quot;<button 
        onClick={() => scrollToSection('sharing-personal-information')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
        WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </button>
        &quot; You must not use the AI Products in any way that violates the terms or policies of any AI Service Provider.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Our AI Products</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        Our AI Products are designed for the following functions:
      </p>
      <ul className="list-disc list-inside text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>AI predictive analytics</li>
      </ul>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">How We Process Your Data Using AI</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed">
        All personal information processed using our AI Products is handled in line with our Privacy Notice and our agreement with third parties. This ensures high security and safeguards your personal information throughout the process, giving you peace of mind about your data&apos;s safety.
      </p>
    </div>
  );
  export const HowLongDoWeKeepYourInformation: React.FC = () => (
    <div id="how-long-do-we-keep-your-information" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We keep your information for as long as necessary to fulfill the purposes outlined in this Privacy Notice unless otherwise required by law.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We will only keep your personal information for as long as it is necessary for the purposes set out in this Privacy Notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the period of time in which users have an account with us.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </p>
    </div>
  );
  
  export const HowDoWeKeepYourInformationSafe: React.FC = () => (
    <div id="how-do-we-keep-your-information-safe" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We aim to protect your personal information through a system of organizational and technical security measures.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
      </p>
    </div>
  );
  
  export const DoWeCollectInformationFromMinors: React.FC = () => (
    <div id="do-we-collect-information-from-minors" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at support@hawkeyevisiontek.com.
      </p>
    </div>
  );
  export const WhatAreYourPrivacyRights: React.FC = () => (
    <div id="what-are-your-privacy-rights" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> Depending on your state of residence in the US or in some regions, such as Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      In some regions (like Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section
       <button 
        onClick={() => scrollToSection('updates-to-notice')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
       &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot;
        </button> below.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We will consider and act upon any request in accordance with applicable data protection laws.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Withdrawing your consent</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section 
      <button 
        onClick={() => scrollToSection('updates-to-notice')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
       &quot;HOW CAN YOU CONTACT US ABOUT THIS NOTICE?&quot;
        </button> below.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Account Information</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        If you would at any time like to review or change the information in your account or terminate your account, you can:
      </p>
      <ul className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>Log in to your account settings and update your user account.</li>
        <li>Contact us using the contact information provided.</li>
      </ul>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Cookies and similar technologies</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        If you have questions or comments about your privacy rights, you may email us at support@hawkeyevisiontek.com.
      </p>
    </div>
  );
  
  export const ControlsForDoNotTrack: React.FC = () => (
    <div id="controls-for-do-not-track" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">11. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognizing or honoring DNT signals, we do not respond to them at this time.
      </p>
    </div>
  );
  
  export const UnitedStatesResidentsSpecificPrivacyRights: React.FC = () => (
    <div id="us-residents-privacy-rights" className="py-4 font-Avenir-Next-Pro">
    <h2 className="heading-2">12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

    <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      <strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana, Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
    </p>

    <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Categories of Personal Information We Collect</h4>
    <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      We have collected the following categories of personal information in the past twelve (12) months:
    </p>

    <table className="min-w-full table-auto mt-4">
      <thead>
        <tr className="bg-gray-100 dark:bg-gray-800">
          <th className="px-4 py-2 text-left text-lg text-gray-700 dark:text-white">Category</th>
          <th className="px-4 py-2 text-left text-lg text-gray-700 dark:text-white">Examples</th>
          <th className="px-4 py-2 text-left text-lg text-gray-700 dark:text-white">Collected</th>
        </tr>
      </thead>
      <tbody>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">A. Identifiers</td>
          <td className="px-4 py-2">Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name</td>
          <td className="px-4 py-2">YES</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">B. Personal information as defined in the California Customer Records statute</td>
          <td className="px-4 py-2">Name, contact information, education, employment, employment history, and financial information</td>
          <td className="px-4 py-2">YES</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">C. Protected classification characteristics under state or federal law</td>
          <td className="px-4 py-2">Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">D. Commercial information</td>
          <td className="px-4 py-2">Transaction information, purchase history, financial details, and payment information</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">E. Biometric information</td>
          <td className="px-4 py-2">Fingerprints and voiceprints</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">F. Internet or other similar network activity</td>
          <td className="px-4 py-2">Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">G. Geolocation data</td>
          <td className="px-4 py-2">Device location</td>
          <td className="px-4 py-2">YES</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">H. Audio, electronic, sensory, or similar information</td>
          <td className="px-4 py-2">Images and audio, video or call recordings created in connection with our business activities</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">I. Professional or employment-related information</td>
          <td className="px-4 py-2">Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">J. Education Information</td>
          <td className="px-4 py-2">Student records and directory information</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">K. Inferences drawn from collected personal information</td>
          <td className="px-4 py-2">Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual&apos;s preferences and characteristics</td>
          <td className="px-4 py-2">NO</td>
        </tr>
        <tr className="border-b dark:border-gray-700">
          <td className="px-4 py-2">L. Sensitive personal Information</td>
          <td className="px-4 py-2"></td>
          <td className="px-4 py-2">NO</td>
        </tr>
      </tbody>
    </table>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
      </p>
      <ul className="list-disc pl-6 text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
    <li>Receiving help through our customer support channels;</li>
    <li>Participation in customer surveys or contests; and</li>
    <li>Facilitation in the delivery of our Services and to respond to your inquiries.</li>
    </ul>

    <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
    We will use and retain the collected personal information as needed to provide the Services or for:
    </p>

    <ul className="list-disc pl-6 text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
    <li>Category A - As long as the user has an account with us</li>
    <li>Category B - As long as the user has an account with us</li>
    <li>Category G - As long as the user has an account with us</li>
    <li>Category H - As long as the user has an account with us</li>
    </ul>

      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Sources of Personal Information</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
      Learn more about the sources of personal information we collect in
       <button 
        onClick={() => scrollToSection('what-information-we-collect')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
       &quot;WHAT INFORMATION DO WE COLLECT?&quot;
        </button>
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">How We Use and Share Personal Information</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Learn more about how we use your personal information in the section,  
        <button 
        onClick={() => scrollToSection('how-we-process-your-information')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
       &quot;HOW DO WE PROCESS YOUR INFORMATION?&quot; 
        </button>
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Will your information be shared with anyone else?</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information in the section,
          &quot;<button 
        onClick={() => scrollToSection('sharing-personal-information')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
        WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </button>&quot;
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be  &quot;selling&quot; of your personal information.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Your Rights</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
      </p>
      <ul className="list-disc pl-6 text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>Right to know whether or not we are processing your personal data</li>
        <li>Right to access your personal data</li>
        <li>Right to correct inaccuracies in your personal data</li>
        <li>Right to request the deletion of your personal data</li>
        <li>Right to obtain a copy of the personal data you previously shared with us</li>
        <li>Right to non-discrimination for exercising your rights</li>
        <li>Right to opt out of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects (&quot;profiling&quot;)</li>
      </ul>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Depending upon the state where you live, you may also have the following rights:
      </p>
      <ul className="list-disc pl-6 text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <li>Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota’s privacy law)</li>
        <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California&apos;s and Delaware&apos;s privacy law)&lt;</li>
        <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota&apos;s and Oregon&apos;s privacy law)</li>
        <li>Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota’s privacy law)</li>
        <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)</li>
        <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)</li>
      </ul>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">How to Exercise Your Rights</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        To exercise these rights, you can contact us by submitting a 
        <a 
            href=" https://app.termly.io/notify/d84bb6d8-eb0e-4275-8c09-e7feca64052a" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:underline px-1"
        >
            data subject access request
        </a>,
         by emailing us at <a href="mailto:support@hawkeyevisiontek.com" className="text-blue-500">support@hawkeyevisiontek.com</a>, or by referring to the contact details at the bottom of this document.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We will honor your opt-out preferences if you enact the 
        <a 
            href="https://globalprivacycontrol.org/" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:underline px-1"
        >
             Global Privacy Control
        </a>
        (GPC) opt-out signal on your browser.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Request Verification</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">Appeals</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at <a href="mailto:support@hawkeyevisiontek.com" className="text-blue-500">support@hawkeyevisiontek.com</a>. We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
      </p>
  
      <h4 className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">California &quot;Shine The Light&quot; Law</h4>
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        California Civil Code Section 1798.83, also known as the &quot;Shine The Light&quot; law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section 
        &quot;<button 
        onClick={() => scrollToSection('updates-to-notice')}
        className="text-blue-600 underline focus:outline-none px-1"
        >
       HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </button>&quot;
      </p>
    </div>
  );
  export const DoWeMakeUpdatesToThisNotice: React.FC = () => (
    <div id="do-we-make-updates-to-this-notice" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        <strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        We may update this Privacy Notice from time to time. The updated version will be indicated by an updated &quot;Revised&quot; date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
      </p>
    </div>
  );
  
  export const HowCanYouContactUsAboutThisNotice: React.FC = () => (
    <div id="updates-to-notice" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        If you have questions or comments about this notice, you may contact our Data Protection Officer (DPO) by email at <a href="mailto:support@hawkeyevisiontek.com" className="text-blue-500">support@hawkeyevisiontek.com</a>, or contact us by post at:
      </p>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        HawkeyeVisionTek LLC <br />
        Data Protection Officer <br />
        630 East Southlake Blvd, #133 <br />
        Southlake, TX 76092 <br />
        United States
      </p>
    </div>
  );
  
  export const HowCanYouReviewUpdateOrDeleteTheDataWeCollect: React.FC = () => (
    <div id="contact-us" className="py-4 font-Avenir-Next-Pro">
      <h2 className="heading-2">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
  
      <p className="text-lg text-gray-700 dark:text-white leading-relaxed mt-4">
        Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a
        <a 
            href="https://app.termly.io/notify/d84bb6d8-eb0e-4275-8c09-e7feca64052a" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-blue-600 hover:underline px-1"
        >
            data subject access request
        </a>.
      </p>
    </div>
  );
  