import React from 'react';

interface SVGProps {
  darkMode?: boolean;
}

export const RealTimeIcon: React.FC<SVGProps> = ({ darkMode }) => (
  <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M33 20.2646V14.2646C33 6.76465 30 3.76465 22.5 3.76465H13.5C6 3.76465 3 6.76465 3 14.2646V23.2646C3 30.7646 6 33.7646 13.5 33.7646H19.5'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.9951 22.4997L14.5651 17.8647C15.0751 17.2047 16.0201 17.0847 16.6801 17.5947L19.4252 19.7547C20.0852 20.2647 21.0301 20.1447 21.5401 19.4997L25.0052 15.0297'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M29.2198 24.4946L29.6398 25.3495C29.8498 25.7695 30.3748 26.1596 30.8398 26.2496L31.4098 26.3396C33.1198 26.6246 33.5248 27.8846 32.2948 29.1296L31.7698 29.6545C31.4248 30.0145 31.2298 30.7045 31.3348 31.1845L31.4098 31.4996C31.8748 33.5696 30.7798 34.3645 28.9798 33.2845L28.5898 33.0595C28.1248 32.7895 27.3748 32.7895 26.9098 33.0595L26.5198 33.2845C24.7048 34.3795 23.6098 33.5696 24.0898 31.4996L24.1648 31.1845C24.2698 30.7045 24.0748 30.0145 23.7298 29.6545L23.2048 29.1296C21.9748 27.8846 22.3798 26.6246 24.0898 26.3396L24.6598 26.2496C25.1098 26.1746 25.6498 25.7695 25.8598 25.3495L26.2798 24.4946C27.0898 22.8596 28.4098 22.8596 29.2198 24.4946Z'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const LockIcon: React.FC<SVGProps> = ({ darkMode }) => (
  <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 15.7646V12.7646C9 7.79965 10.5 3.76465 18 3.76465C25.5 3.76465 27 7.79965 27 12.7646V15.7646'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18 28.5146C20.0711 28.5146 21.75 26.8357 21.75 24.7646C21.75 22.6936 20.0711 21.0146 18 21.0146C15.9289 21.0146 14.25 22.6936 14.25 24.7646C14.25 26.8357 15.9289 28.5146 18 28.5146Z'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.5 33.7646H10.5C4.5 33.7646 3 32.2646 3 26.2646V23.2646C3 17.2646 4.5 15.7646 10.5 15.7646H25.5C31.5 15.7646 33 17.2646 33 23.2646V26.2646C33 32.2646 31.5 33.7646 25.5 33.7646Z'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const BarChartIcon: React.FC<SVGProps> = ({ darkMode }) => (
  <svg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 33.7646H33'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M14.625 6.76465V33.7646H21.375V6.76465C21.375 5.11465 20.7 3.76465 18.675 3.76465H17.325C15.3 3.76465 14.625 5.11465 14.625 6.76465Z'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.5 15.7646V33.7646H10.5V15.7646C10.5 14.1146 9.9 12.7646 8.1 12.7646H6.9C5.1 12.7646 4.5 14.1146 4.5 15.7646Z'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.5 23.2646V33.7646H31.5V23.2646C31.5 21.6146 30.9 20.2646 29.1 20.2646H27.9C26.1 20.2646 25.5 21.6146 25.5 23.2646Z'
      stroke={darkMode ? 'white' : 'black'}
      strokeWidth='2.25'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const MailboxIcon: React.FC<SVGProps> = ({ darkMode }) => (
    <svg
      width="36"
      height="37"
      viewBox="0 0 36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 14.2646V11.2646C3 6.76465 6 3.76465 10.5 3.76465H25.5C30 3.76465 33 6.76465 33 11.2646V14.2646"
        stroke={darkMode ? 'white' : 'black'}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 23.2646V26.2646C3 30.7646 6 33.7646 10.5 33.7646H25.5C30 33.7646 33 30.7646 33 26.2646V23.2646"
        stroke={darkMode ? 'white' : 'black'}
        strokeWidth="2.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0498 14.6548L17.9998 19.2598L25.8898 14.6848"
        stroke={darkMode ? 'white' : 'black'}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 27.4196V19.2446"
        stroke={darkMode ? 'white' : 'black'}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1398 10.1997L11.3399 12.8698C10.2599 13.4698 9.35986 14.9848 9.35986 16.2298V21.3148C9.35986 22.5598 10.2449 24.0748 11.3399 24.6748L16.1398 27.3447C17.1598 27.9147 18.8399 27.9147 19.8749 27.3447L24.6749 24.6748C25.7549 24.0748 26.6549 22.5598 26.6549 21.3148V16.2298C26.6549 14.9848 25.7699 13.4698 24.6749 12.8698L19.8749 10.1997C18.8399 9.61474 17.1598 9.61474 16.1398 10.1997Z"
        stroke={darkMode ? 'white' : 'black'}
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  