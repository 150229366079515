import { createAsyncThunk, isRejectedWithValue } from '@reduxjs/toolkit';
import { getHeadCountIntervalReportRequest } from 'api/dashboard';
import { AxiosError } from 'axios';
import { THeadCountIntervalParams } from '../dashboardSlice/type';

export const getHeadCountIntervalReport = createAsyncThunk(
  'AnalyticsSlice/getHeadCountIntervalReport',
  async ({ device_Id, interval, hours = 2 }: THeadCountIntervalParams) => {
    try {
      const response = await getHeadCountIntervalReportRequest({
        device_Id,
        interval,
        hours,
      });

      return response.data;
    } catch (error) {
      const axiosError = error as AxiosError;
      return isRejectedWithValue(axiosError.response?.data || axiosError.message); // Handle error properly
    }
  },
);
