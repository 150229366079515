import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import toast from 'react-hot-toast';

import { devicesApi, mediaApi } from 'api';
import { DeviceToastMessages } from 'constants/ToastMessages';

import {
  TAddNewDevicesParams,
  TDeviceParams,
  TDevicesBoundaryEdit,
  TDevicesConfig,
  TDevicesParams,
  TEditwDevicesParams,
  TUpdateDeviceParams,
  TDeleteDeviceParams,
  TUpdateDeviceVerticesParam
} from './type';
import { TMediaParams } from 'api/media/types';

export const getDevices = createAsyncThunk(
  'devicesSlice/getDevicesRequest',
  async (options?: TDevicesParams) => {
    try {
      const response = await devicesApi.getDevicesRequest(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const getDevice = createAsyncThunk(
  'devicesSlice/getDevice',
  async (options: TDeviceParams) => {
    try {
      const response = await devicesApi.getDeviceByIdRequest(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;

      throw Error;
    }
  },
);

export const postDevices = createAsyncThunk(
  'devicesSlice/getDevicesRequest',
  async ({
    onSuccess,
    onFailure,
    ...options
  }: { onSuccess?: () => void; onFailure?: () => void } & TAddNewDevicesParams) => {
    const toastId = toast.loading(DeviceToastMessages.DEVICE_CREATION);
    try {
      const response = await devicesApi.AddNewDevicesRequest(options);
      toast.success(DeviceToastMessages.DEVICE_CREATION_SUCCESS, {
        id: toastId,
      });

      onSuccess?.();
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message || 'Unknowned error occured', { id: toastId });

      onFailure?.();
      throw Error;
    }
  },
);

export const editDevices = createAsyncThunk(
  'devicesSlice/getDevicesRequest',
  async (options: TEditwDevicesParams) => {
    try {
      const response = await devicesApi.EditDevicesRequest(options);
      toast.success('Device Successfully updated!');
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message || 'Unknowned error occured');
      throw Error;
    }
  },
);

export const getDevicesHistoryByID = createAsyncThunk(
  'devicesSlice/devicesHistoryByIDSlice',
  async (options: TDevicesParams) => {
    try {
      const response = await devicesApi.deviceHistoryById(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      // toast.error(Error.message || 'Unknowned error occured');
      throw Error;
    }
  },
);

export const postDevicesVertices = createAsyncThunk(
  'devicesSlice/postDevicesVertices',
  async (options: TDevicesBoundaryEdit) => {
    try {
      const response = await devicesApi.getDeviceRoi(options);
      toast.success('Saved!');
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message || 'Unknowned error occured');
      throw Error;
    }
  },
);

export const devicesPanTilt = createAsyncThunk(
  'devicesSlice/devicesPanTilt',
  async (options: TDevicesConfig, { dispatch }) => {
    try {
      const response = await devicesApi.devicesConfigRequest(options);
      const params = {
        limit: 10,
        offset: 0,
      };
      dispatch(getDevices(params));
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      toast.error(Error.message || 'Unknowned error occured');
      throw Error;
    }
  },
);

export const getDevicesCounts = createAsyncThunk('devicesSlice/getDevicesCounts', async () => {
  try {
    const response = await devicesApi.getDevicesCountsRequest();
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    // throw err;
  }
});

export const startStream = createAsyncThunk(
  'devicesSlice/startStream',
  async (device_id: number) => {
    try {
      const response = await devicesApi.startStreamRequest(device_id);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const endStream = createAsyncThunk('devicesSlice/endStream', async (device_id: number) => {
  try {
    const response = await devicesApi.endStreamRequest(device_id);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err;
  }
});

export const getBatchUrls = createAsyncThunk(
  'devicesSlice/getBatchUrls',
  async (params: TMediaParams) => {
    try {
      const response = await mediaApi.getBatchUrlsRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const updateDevice = createAsyncThunk(
  'devicesSlice/updateDevice',
  async (params: TUpdateDeviceParams) => {
    try {
      const response = await devicesApi.updateDeviceRequest(params);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err;
    }
  },
);

export const getDevicesVertices = createAsyncThunk(
  'devicesSlice/getDevicesVertices',
  async (options: { device_id: number; limit: number; offset: number }) => {
    try {
      const response = await devicesApi.getDevicesVertices(options);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const deleteDeviceVertices = createAsyncThunk(
  'devicesSlice/deleteDeviceVertices',
  async ({ device_id, id }: TDeleteDeviceParams) => {
    try {
      const response = await devicesApi.deleteDeviceVertices(device_id, id);
      return response.data;
    } catch (error) {
      const Error = error as AxiosError;
      throw Error;
    }
  },
);

export const editDeviceVertices = createAsyncThunk('devicesSlice/editDeviceVertices', 
async (body: TUpdateDeviceVerticesParam) => {
  try {
    const response = await devicesApi.EditDevicesVertices(body);
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    throw err;
  }
},
)