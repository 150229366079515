import Badge from '../../components/shared/Badge';
import Card from '../../components/shared/Card';
import Container from '../../components/shared/Container';
import { useState, useEffect } from 'react';
import { useDarkMode } from 'hooks';

const Experience = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const { darkMode } = useDarkMode();
  useEffect(() => {
    setIsDarkMode(document.body.classList.contains('dark'));
  }, []);


  const experienceInfo = [
    {
      icons: darkMode ? 'images/darkEnhanced.svg' : 'images/security.svg',
      title: 'Enhanced Security',
      subTitle:
        'AI can analyze large amounts of footage in real-time, making it easier to identify and prevent security breaches.',
    },
    {
      icons: darkMode ? 'images/darkSettings.svg' : 'images/efficiency.svg',
      title: 'Operational Efficiency',
      subTitle:
        'This optimization can lead to better customer experiences, reduced wait times, and increased efficiency.',
    },
    {
      icons: darkMode ? 'images/darkAnalytics.svg' : 'images/analyst.svg',
      title: 'Insights & Analytics',
      subTitle:
        'These insights enable businesses to make informed choices to improve engagement and increase revenue.',
    },
  ];

  return (
    <Container className='flex flex-col md:flex-row gap-x-10 gap-y-10 xl:py-[70px] lg:py-16 py-8 md:py-14'>
      <div className='md:w-1/2  w-full'>
        <div className='rounded-[18px] mx-auto lg:mx-0 center-child flex-col p-10 md:h-[400px] md:w-[350px]  lg:h-[467px] h-[300px] lg:w-[400px] w-[250px] relative bg-accent'>
          <img
            src='images/dashboard.svg'
            alt=''
            className='absolute xl:-right-32 w-[230px] md:w-[280px] lg:w-[350px] xl:w-[400px] -right-10 md:right-3 lg:-right-10 scale-125'
          />
        </div>
      </div>
      <div className='md:w-1/2 w-full'>
        <div className='flex flex-col md:items-start items-center'>
          <Badge title='AI DRIVEN ANALYTICS' />
          <h3 className='md:text-[40px] text-[18px] font-semibold capitalize mb-2  font-Avenir-Next-Pro'>
            Experience superior technology
          </h3>
          <p className='text-[16px] font-light text-sub-title dark:text-dark-sub-title md:text-left text-center mb-4'>
            Our unique solutions and superior technology enables you to stay ahead of the curve, and
            enables you to operate with confidence.
          </p>
        </div>
        <div className='grid grid-cols-1  mt-1 place-items-center'>
          {experienceInfo.map((analytic, index) => (
            <Card
              key={String(index + 1)}
              icon={analytic.icons}
              title={analytic.title}
              subTitle={analytic.subTitle}
              isCol
            />
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Experience;