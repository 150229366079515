import { ArrowLongUpIcon } from '@heroicons/react/24/solid';
import React from 'react';

interface CardProps {
  icon?: string;
  title: string;
  linkTitle?: string;
  subTitle: string;
  className?: string;
  isBg?: boolean;
  isCol?: boolean;
  svg?: React.ReactNode;
}
const Card: React.FC<CardProps> = ({ icon, title, subTitle, className, isBg, isCol, svg }) => {
  if (isBg) {
    return (
      <div className='group'>
        <div
          className={`w-[270px] h-[340px] center-child flex-col p-4 rounded-[32px] bg-card-bg-main dark:bg-dark-card-bg group-hover:bg-[#ebebeb] dark:group-hover:bg-[#363d4a] group cursor-pointer group, ${className}`}
        >
          <img src={icon} alt='' className='w-100 h-[100px] mb-6' />
          <h6 className='text-[20px] text-center font-semibold mb-2 font-Avenir-Next-Pro '>
            {title}
          </h6>
          <p className='text-[12px] text-center font-light font-Avenir-Next-Pro'>{subTitle}</p>
        </div>
      </div>
    );
  }
  return (
    <div className={` flex justify-start flex-col p-3  gap-y-4, ${className}`}>
      {isCol ? (
        <div className='flex gap-4'>
          <div>
            <img src={icon} alt='' className='h-[36px] w-[36px]' />
          </div>
          <div>
            <h6 className='text-[20px] font-semibold font-Avenir-Next-Pro'>{title}</h6>
            <p className='text-[13px] font-light '>{subTitle}</p>
          </div>
        </div>
      ) : (
        <>
          {svg ? (
            <div className='mb-2'>{svg}</div>
          ) : (
            <img src={icon} alt='' className='h-[36px] w-[36px] mb-2' />
          )}
          <h6 className='text-[20px] font-semibold font-Avenir-Next-Pro'>{title}</h6>
          <p className='text-[13px] font-light font-Avenir-Next-Pro'>{subTitle}</p>
        </>
      )}
    </div>
  );
};

Card.defaultProps = {
  icon: '',
  className: '',
  isBg: false,
  isCol: false,
  linkTitle: '',
};
export default Card;